import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SuspenseImage } from '@vestwell-frontend/ui';

import { FC, ReactNode } from 'react';

import { useConfig } from '../contexts';

const Content = styled('div')(props => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: props.theme.spacing(8),
  paddingTop: props.theme.spacing(8),
  width: '100%'
}));

const StyledCard = styled(Card, {
  shouldForwardProp: propName => propName !== 'isLogo'
})<{ isLogo: boolean }>(props => ({
  alignItems: 'center',
  borderRadius: '4px',
  borderTopColor: props.theme.palette.primary.main,
  borderTopWidth: '3px',
  display: 'flex',
  flexDirection: 'column',
  minHeight: props.isLogo ? '610px' : '530px',
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [props.theme.breakpoints.up('sm')]: {
    marginTop: props.isLogo ? '20%' : undefined,
    width: '440px'
  }
}));

export const BackgroundCard: FC<{ children?: ReactNode }> = props => {
  const config = useConfig();

  return (
    <StyledCard isLogo={!!config.login?.logo}>
      {!!config.login?.logo && (
        <SuspenseImage
          alt={`${
            ['connect', 'signin'].includes(config.subdomain)
              ? 'vestwell'
              : config.subdomain
          } logo`}
          className='mt-8 h-12'
          data-component='logo'
          // todo:: remove after updating in db
          src={config.login.logo
            .replace('/clients/login/logos', '/login')
            .replace('.png', '.webp')}
        />
      )}
      <Content data-component='content'>{props.children}</Content>
    </StyledCard>
  );
};

BackgroundCard.displayName = 'BackgroundCard';
