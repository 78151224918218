import { createSvgIcon, SvgIconProps } from '@mui/material';

import { FC } from 'react';

export const AvatarIcon: FC<SvgIconProps> = createSvgIcon(
  <svg
    fill='none'
    height='132'
    viewBox='0 0 132 132'
    width='132'
    xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_629_68979)'>
      <rect fill='white' height='120' rx='60' width='120' x='6' y='2' />
      <path
        d='M35 79.4927C35 65.6341 46.2346 54.3994 60.0933 54.3994C65.4247 54.3994 70.3678 56.0621 74.4323 58.8972M74.4323 33.339C74.4323 41.2582 68.0125 47.678 60.0933 47.678C52.1741 47.678 45.7543 41.2582 45.7543 33.339C45.7543 25.4198 52.1741 19 60.0933 19C68.0125 19 74.4323 25.4198 74.4323 33.339Z'
        stroke='#2C8FC5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <rect
        fill='white'
        height='25.1928'
        rx='2.68856'
        stroke='#39CC97'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        width='33.0815'
        x='65.918'
        y='77.6007'
      />
      <rect
        fill='#E7FFF3'
        height='18.82'
        rx='2.24047'
        width='26.8856'
        x='69.0166'
        y='80.8369'
      />
      <path
        d='M72.5342 77.6007V71.4933C72.5342 66.0122 76.9775 61.5688 82.4586 61.5688V61.5688C87.9398 61.5688 92.3831 66.0122 92.3831 71.4933V77.6007'
        stroke='#39CC97'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M76.0967 77.6007V71.4933C76.0967 67.9798 78.945 65.1315 82.4585 65.1315V65.1315C85.9721 65.1315 88.8203 67.9798 88.8203 71.4933V77.6007'
        stroke='#39CC97'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M73.8066 89.5609L79.7868 95.541L90.8563 84.4714'
        stroke='#39CC97'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
        height='132'
        id='filter0_d_629_68979'
        width='132'
        x='0'
        y='0'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feMorphology
          in='SourceAlpha'
          operator='erode'
          radius='4'
          result='effect1_dropShadow_629_68979'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='5' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'
        />
        <feBlend
          in2='BackgroundImageFix'
          mode='normal'
          result='effect1_dropShadow_629_68979'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_629_68979'
          mode='normal'
          result='shape'
        />
      </filter>
    </defs>
  </svg>,
  'AvatarIcon'
);

AvatarIcon.defaultProps = {
  viewBox: '0 0 132 132'
};

AvatarIcon.displayName = 'AvatarIcon';
