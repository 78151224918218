import isPropValid from '@emotion/is-prop-valid';
import { Box, styled } from '@mui/material';
import type { HttpErrorType } from '@vestwell-frontend/hooks';

import {
  Children,
  CSSProperties,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useMemo
} from 'react';

import { Loader } from './Loader';
import { PageContext } from './PageContext';
import { StatusCodeError } from './StatusCodeError';

interface StyledInnerDivProps {
  innerStyles?: CSSProperties;
}

const Content = styled('div', {
  shouldForwardProp: prop => prop !== 'innerStyles'
})<StyledInnerDivProps>(({ innerStyles }) => ({
  ...innerStyles
}));

/**
 * Page
 * Scrollable, stretched layout for content
 */
export type PageProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  'data-component'?: string;
  children: ReactNode;
  /** when truthy it displays the error message */
  error?: Pick<HttpErrorType, 'code' | 'status'>;
  /** Slot to render footer within layout */
  footer?: ReactNode;
  /** adds extra padding in mobile view for drawer button (padding-left) */
  hasDrawer?: boolean;
  /** adds extra padding in mobile view for shelf button (padding-right) */
  hasShelf?: boolean;
  /** add css styles to the nested div */
  innerStyles?: CSSProperties;
  /** display the loading indicator */
  isLoading?: boolean;
  /** temporary workaround for portals with corrected page structure */
  noBottomPadding?: boolean;
};

const StyledPage = styled('div', {
  shouldForwardProp: isPropValid
})<{
  hasDrawer?: boolean;
  hasShelf?: boolean;
}>(props => ({
  '& + [data-component="pageFooter"]': {
    paddingLeft: props.theme.spacing(12),
    paddingRight: props.theme.spacing(props.hasShelf ? 6 : 12),
    [props.theme.breakpoints.down('lg')]: {
      paddingLeft: props.theme.spacing(10),
      paddingRight: props.theme.spacing(10)
    },
    [props.theme.breakpoints.down('sm')]: {
      paddingLeft: props.theme.spacing(props.hasShelf ? 10 : 4),
      paddingRight: props.theme.spacing(props.hasShelf ? 10 : 4)
    }
  },
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gridArea: 'page',
  height: '100%',
  overflowY: 'auto',
  paddingLeft: props.theme.spacing(12),
  paddingRight: props.theme.spacing(props.hasShelf ? 6 : 12),
  paddingTop: props.theme.spacing(8),
  position: 'relative',
  width: '100%',
  [props.theme.breakpoints.down('lg')]: {
    padding: props.theme.spacing(10)
  },
  [props.theme.breakpoints.down('sm')]: {
    padding: props.theme.spacing(
      84 / 4,
      props.hasShelf ? 10 : 4,
      68 / 4,
      props.hasDrawer ? 10 : 4
    ),
    width: '100%'
  }
}));

const FooterWrapper = styled('div')(props => ({
  marginTop: 'auto',
  paddingTop: props.theme.spacing(8),
  width: '100%',
  [props.theme.breakpoints.down('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

export const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      className,
      error,
      footer,
      innerStyles,
      isLoading,
      noBottomPadding,
      ...props
    },
    ref
  ) => {
    const ctx = useMemo(
      () => ({
        hasMainSiblings: Children.count(children) > 1
      }),
      [children]
    );

    return (
      <PageContext.Provider value={ctx}>
        <StyledPage {...props} className={className} id='mainContent' ref={ref}>
          <Content innerStyles={innerStyles}>
            {isLoading && <Loader />}
            {error && (
              <Box
                alignItems='center'
                bottom={0}
                display='flex'
                height='100%'
                justifyContent='center'
                left={0}
                position='absolute'
                right={0}
                top={0}
                width='100%'>
                <StatusCodeError code={error.code} status={error.status} />
              </Box>
            )}
            {!isLoading && children}
          </Content>
          {!isLoading && footer && <FooterWrapper>{footer}</FooterWrapper>}
        </StyledPage>
      </PageContext.Provider>
    );
  }
);

Page.defaultProps = {
  'data-component': 'Page',
  hasDrawer: false,
  hasShelf: false
};

Page.displayName = 'Page';
