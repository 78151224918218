import { maskEmail } from '@vestwell-frontend/helpers';

import { FC, useMemo } from 'react';

export type FormatEmailProps = {
  mask?: boolean;
  showPlaceholder?: boolean;
  value: string;
};

export const FormatEmail: FC<FormatEmailProps> = props => {
  const formatted = useMemo(() => {
    return props.value
      ? props.mask
        ? maskEmail(props.value)
        : props.value
      : props.showPlaceholder
      ? '--'
      : '';
  }, [props.showPlaceholder, props.value]);

  return <>{formatted}</>;
};

FormatEmail.defaultProps = {
  showPlaceholder: false,
  value: ''
};

FormatEmail.displayName = 'FormatEmail';
