import { useMediaQuery } from '@mui/material';
import { AccountMenu } from '@vestwell-frontend/elements';
import { Topbar } from '@vestwell-frontend/ui';

import cn from 'clsx';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-use';

import { routes } from '../router/routes';
import { BackgroundCard } from './BackgroundCard';
import { Banner } from './Banner';
import { Help } from './Help';
import { Page } from './Page';

export const Root: FC<{
  children?: ReactNode;
  isSpecialPage?: boolean;
}> = props => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const path = useLocation();

  const isSpecialPage =
    [routes.LOGIN_AND_SECURITY, routes.PERSONAS].includes(
      path.pathname as any
    ) || props.isSpecialPage;

  const isTopBar = path.pathname === routes.PERSONAS;

  return (
    <>
      {isTopBar && (
        <Topbar>
          <div className='ml-auto'>
            <AccountMenu />
          </div>
        </Topbar>
      )}
      {!isSpecialPage && !isMobile && <Banner />}
      <Page>
        {isSpecialPage ? (
          props.children || <Outlet />
        ) : (
          <>
            {isSpecialPage && isMobile && <Banner />}
            <div
              className={cn('flex flex-col', {
                'items-center mx-auto my-auto': !isMobile,
                'w-100': isMobile
              })}>
              <BackgroundCard>{props.children || <Outlet />}</BackgroundCard>
              <Help />
            </div>
          </>
        )}
      </Page>
    </>
  );
};

Root.displayName = 'Root';
