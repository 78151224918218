import { Skeleton } from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import AbortablePromise from 'promise-abortable';
import { FC, useEffect, useState } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';

export const Img: FC<Partial<HTMLImageElement> & { isLoading?: boolean }> = ({
  isLoading: loading,
  ...props
}) => {
  const [isLoading, toggleIsLoading] = useToggle(true);
  const [src, setSrc] = useState(undefined);

  const loaderId = useCamelCase(`${props['data-component']} Loader`);

  useEffect(() => {
    toggleIsLoading(true);

    if (!props.src) {
      return;
    }

    const promise = new AbortablePromise((resolve, reject, signal) => {
      const img = new Image();

      img.onload = () => {
        toggleIsLoading(false);
        setSrc(props.src);
        resolve();
      };

      img.onerror = () => {
        toggleIsLoading(false);
        setSrc(props.src);
        resolve();
      };

      img.src = props.src;

      signal.onabort = () => {
        img.onerror = null;
        img.onload = null;
        img.src = null;
      };
    });

    return () => {
      promise.abort();
      setSrc(undefined);
    };
  }, [props.src]);

  useUpdateEffect(() => {
    toggleIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading || !src ? (
        <Skeleton
          animation='wave'
          data-component={loaderId}
          height={props.height}
          variant='rectangular'
          width={props.width}
        />
      ) : (
        <img {...(props as any)} alt={props.alt} src={src} />
      )}
    </>
  );
};

Img.displayName = 'Img';

Img.defaultProps = {
  height: 1,
  width: 1
};
