import {
  Check,
  Close,
  InfoOutlined,
  WarningAmberOutlined
} from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

import {
  MaterialDesignContent,
  SnackbarProvider,
  SnackbarProviderProps
} from 'notistack';
import { FC, ReactNode, useRef } from 'react';

export type NotificationsProviderProps = SnackbarProviderProps & {
  children: ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent': {
      fontSize: theme.spacing(4)
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.info.main
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main
    }
  })
);

export const NotificationsProvider: FC<NotificationsProviderProps> = ({
  children,
  ...props
}) => {
  const $snackbar = useRef(null);

  const onDismiss = e => {
    $snackbar.current.closeSnackbar(e.target.dataset.key);
  };

  return (
    <SnackbarProvider
      Components={{
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent
      }}
      action={key => (
        <IconButton
          aria-label='Close Notification'
          className='mr-4'
          color='white'
          data-key={key}
          onClick={onDismiss}
          size='small'>
          <Close />
        </IconButton>
      )}
      iconVariant={{
        error: <InfoOutlined className='mr-2' color='white' fontSize='small' />,
        info: <InfoOutlined className='mr-2' color='white' fontSize='small' />,
        success: <Check className='mr-2' color='white' fontSize='small' />,
        warning: (
          <WarningAmberOutlined
            className='mr-2'
            color='white'
            fontSize='small'
          />
        )
      }}
      {...props}
      ref={$snackbar}>
      {children}
    </SnackbarProvider>
  );
};

NotificationsProvider.defaultProps = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom'
  },
  autoHideDuration: 3000,
  maxSnack: 6,
  preventDuplicate: true
};
