import { Close } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  IconButton,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  styled
} from '@mui/material';

import cn from 'clsx';
import {
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useId,
  useMemo
} from 'react';

import { ModalBody } from './ModalBody';
import { ModalContainer } from './ModalContainer';
import { ModalHeader } from './ModalHeader';

export type ModalProps = Pick<MuiModalProps, 'disableEnforceFocus' | 'ref'> & {
  /** Text content labeling the modal */
  'aria-label'?: string;
  /** ID of an element that labels the modal */
  'aria-labelledby'?: string;
  /** ID of an element with additional details about the modal */
  'aria-describedby'?: string;
  /** unique id; used for test selector */
  'data-testid'?: string;
  /** Text or component content */
  children: ReactNode;
  /** Display a close button */
  closeButton?: boolean;
  /** close confirmation dialog text */
  confirmMessage?: string;
  /** Text or component content for sticky header */
  header?: ReactNode;
  /** loading state of the modal */
  isLoading?: boolean;
  /** Determines the modal's visibility */
  isOpen?: boolean;
  /** sets width of modal for lg breakpoint */
  lgWidth?: number | string;
  /** Disable ability to close */
  locked?: boolean;
  /** sets width of modal for md breakpoint */
  mdWidth?: number | string;
  /** Handler function to close modal using keyboard  */
  onRequestClose(e?: SyntheticEvent): void;
  /** Make the user confirm before closing */
  requireConfirmOnClose?: boolean;
  /** adds scroll event handler to modal body */
  onScroll?: (e?: SyntheticEvent) => void;
  /** Aria role */
  role?: string;
  /** @deprecated - do not override styling; stay consistent */
  styles?: {
    /** className to merge into container styles */
    container?: string;
    /** className to merge into background overlay styles */
    overlay?: string;
  };
};

const ModalLoader = styled('div', { name: 'modalLoader' })({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minHeight: 350,
  width: '100%'
});

export const Modal: FC<ModalProps> = props => {
  const labelId = useId();

  const ariaLabelledBy = useMemo(
    () => (typeof props.header === 'string' ? labelId : undefined),
    [labelId, props.header]
  );

  const handleClose = useCallback(() => {
    if (!props.requireConfirmOnClose) {
      return props.onRequestClose();
    }

    if (window.confirm(props.confirmMessage)) {
      props.onRequestClose();
    }
  }, [props.confirmMessage, props.onRequestClose, props.requireConfirmOnClose]);

  return (
    <MuiModal
      className='fixed flex top-0 right-0 left-0 justify-center items-center'
      onClose={props.locked ? undefined : handleClose}
      open={props.locked || props.isOpen}
      role='presentation'
      slotProps={{
        backdrop: {
          className: props.styles?.overlay
        }
      }}>
      <ModalContainer
        aria-describedby={props['aria-describedby']}
        aria-label={props['aria-label']}
        aria-labelledby={props['aria-labelledby'] ?? ariaLabelledBy}
        className={props.styles?.container}
        data-component='modalContainer'
        data-testid={props['data-testid']}
        lgWidth={props.lgWidth}
        mdWidth={props.mdWidth}
        role={props.role}>
        {!props.isLoading && props.header && (
          <>
            <ModalHeader id={ariaLabelledBy}>{props.header}</ModalHeader>
            <Divider />
          </>
        )}
        {props.isLoading ? (
          <ModalBody isRounded={!props.header}>
            <ModalLoader>
              <CircularProgress />
            </ModalLoader>
          </ModalBody>
        ) : (
          props.children
        )}
        {props.closeButton && (
          <IconButton
            aria-label='close'
            className={cn('text-black absolute', {
              'top-1 right-0': !!props.header,
              'top-2 right-2': !props.header
            })}
            onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </ModalContainer>
    </MuiModal>
  );
};

Modal.defaultProps = {
  closeButton: false,
  confirmMessage:
    'Your data is not saved yet. Are you sure you want to close the form?',
  isLoading: false,
  isOpen: false,
  role: 'dialog',
  styles: {}
};

Modal.displayName = 'Modal';
