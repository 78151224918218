import { Alert } from '@mui/material';

import { useFormikContext } from 'formik';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';

import { Button, ButtonProps } from '../Button';

export type FormSaveButtonProps = ButtonProps & {
  'data-component'?: string;
  ensureDirty?: boolean;
  serverError?: ReactNode;
};

export const FormSaveButton = forwardRef<
  HTMLButtonElement,
  FormSaveButtonProps
>(({ ensureDirty, serverError, ...props }, ref) => {
  const ctx = useFormikContext<any>();

  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(
      props.disabled ||
        ctx.isSubmitting ||
        !ctx.isValid ||
        (ctx.status === 'error' && !ctx.dirty) ||
        (ensureDirty && !ctx.dirty)
    );
  }, [
    ctx.dirty,
    ctx.isSubmitting,
    ctx.isValid,
    ctx.status,
    ensureDirty,
    props.disabled
  ]);

  const handleClick = useCallback(
    e => {
      ctx.handleSubmit(e);
    },
    [ctx.handleSubmit]
  );

  return (
    <>
      <Button
        {...props}
        data-disabled={isDisabled}
        data-error={ctx.status === 'error'}
        data-is-dirty={ctx.dirty}
        data-is-valid={ctx.isValid}
        data-status={ctx.status}
        data-submitting={ctx.isSubmitting}
        disabled={isDisabled}
        loading={props.loading || ctx.isSubmitting}
        onClick={handleClick}
        ref={ref}
      />
      {serverError && (
        <Alert className='w-fit-content' severity='error'>
          {serverError}
        </Alert>
      )}
    </>
  );
});

FormSaveButton.displayName = 'FormSaveButton';

FormSaveButton.defaultProps = {
  children: 'Save',
  'data-component': 'formSubmitButton',
  disabled: false,
  type: 'submit',
  variant: 'contained'
};
