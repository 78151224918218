import { Alert as MaitenanceAlert } from '@hermes/ui';
import { Alert } from '@mui/material';
import { usePostLogin } from '@sentinel/hooks';
import { validateEmail } from '@vestwell-frontend/helpers';
import {
  useDocumentTitle,
  useNotifications,
  useSearchParams
} from '@vestwell-frontend/hooks';
import { Form, FormField, Link, Text } from '@vestwell-frontend/ui';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';
import * as Yup from 'yup';

import { SubmitButton } from '../components';
import { useConfig } from '../contexts';
import { routes } from '../router/routes';

export const loginSchema = Yup.object().shape({
  email: validateEmail('Please use standard email format').required('Required'),
  password: Yup.string().required('Required')
});

export function SignInPage() {
  useDocumentTitle('Sign In');

  const notify = useNotifications();
  const navigate = useNavigate();
  const params = useSearchParams();

  const [, setCodeLastSentTime] = useLocalStorage('codeLastSentTime', {});

  const config = useConfig();

  const login = usePostLogin();

  const submitLogIn = useCallback(
    async values => {
      await login.mutateAsync({
        data: {
          password: values.password,
          redirectParams: params,
          username: values.email
        }
      });
    },
    [params]
  );

  useEffectOnce(() => {
    if (params.error) {
      notify.error(
        'You have been logged out due to inactivity. Please log in again.',
        {
          persist: true
        }
      );

      window.history.replaceState({}, '', routes.SIGN_IN);
    }
    setCodeLastSentTime({});
  });

  useUpdateEffect(() => {
    if (login.error?.code === 'LOCKED_OUT') {
      navigate(routes.ACCOUNT_LOCKED);
    }
  }, [login.error]);

  useUpdateEffect(() => {
    if (login.data?.isMfaEnabled) {
      navigate(routes.MFA_VERIFICATION);
      return;
    }

    if (login.data?.hasMfaNudge) {
      navigate(routes.MFA_NUDGE);
      return;
    }

    if (login.data) {
      navigate(routes.PERSONAS);
    }
  }, [login.data]);

  return (
    <>
      <Text color='emphasize' data-testid='signInTitle' mb={8} variant='b2'>
        Sign In
      </Text>
      <MaitenanceAlert />
      <Form
        className='space-y-2 w-64'
        initialValues={{
          email: '',
          password: ''
        }}
        onChange={login.reset}
        onSubmit={submitLogIn}
        validationSchema={loginSchema}>
        <FormField
          autoComplete='on'
          className='w-64'
          data-testid='emailField'
          fieldClassName='w-64'
          format='email'
          label='Email'
          name='email'
        />
        <FormField
          className='w-64'
          fieldClassName='w-64'
          label='Password'
          name='password'
          revealable
          type='password'
        />
        <div className='flex flex-col items-center'>
          {login.error && (
            <Alert className='mt-0 w-fit-content' severity='error'>
              {login.error?.message}
            </Alert>
          )}
          <Link
            className='link-4'
            data-testid='forgotPasswordLink'
            to={routes.FORGOT_PASSWORD}>
            Forgot your password?
          </Link>
          <SubmitButton disabled={!!login.error}>Sign In</SubmitButton>
        </div>
      </Form>
      <Text color='grey100' variant='i2'>
        {config.login?.accountSetup?.link?.description
          ? `${config.login?.accountSetup?.link?.description} `
          : `Don't have an account yet? `}
        <a
          className='link-4 text-primary'
          data-testid='registrationLink'
          href={`${window.location.href.replace('signin', 'connect')}${
            config.login?.accountSetup
              ? routes.ACCOUNT_SETUP.slice(1)
              : config.registration?.isState
                ? routes.EMPLOYER_REGISTRATION.slice(1)
                : routes.REGISTRATION.slice(1)
          }`}>
          {config.login?.accountSetup?.link?.text ?? 'Start here'}
        </a>
      </Text>
    </>
  );
}

SignInPage.displayName = 'SignInPage';
