import { PaletteColor, styled } from '@mui/material';

import { Children, cloneElement, forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

function injectLinkedProp(children) {
  if (
    Children.count(children) > 1 ||
    !children.type ||
    children.type.name !== 'Card'
  ) {
    return children;
  }
  return cloneElement(children, { isLinked: true });
}

export type LinkProps = Omit<RouterLinkProps, 'to'> & {
  'data-component'?: string;
  'data-testid'?: string;
  external?: boolean;
  to?: string;
};

const LinkStyled = styled(RouterLink)(props => ({
  color: (props.theme.palette[props.color] as PaletteColor)?.main
}));

const AnchorStyled = styled('a')(props => ({
  color: (props.theme.palette[props.color] as PaletteColor)?.main
}));

AnchorStyled.displayName = 'AnchorStyled';

export const Link = forwardRef<any, LinkProps>(
  ({ external, to, children, className, ...props }, ref) => {
    const isExternal = external || (to && to.includes(':'));
    const target = isExternal ? '_blank' : undefined; // external + URL schemes

    if (isExternal || !to) {
      return (
        <AnchorStyled
          {...props}
          className={className}
          href={to}
          ref={ref}
          target={target}>
          {children}
        </AnchorStyled>
      );
    }

    const linkedChildren = injectLinkedProp(children);

    return (
      <LinkStyled
        {...(props as any)}
        className={className}
        ref={ref}
        target={target}
        to={to}>
        {linkedChildren}
      </LinkStyled>
    );
  }
);

Link.displayName = 'Link';

Link.defaultProps = {
  color: 'primary',
  'data-component': 'link'
};
