import { Menu, MenuItem, MenuProps, Palette, styled } from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import {
  cloneElement,
  ElementType,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useId,
  useMemo,
  useState
} from 'react';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiMenu-list': {
      padding: 0
    },
    borderRadius: theme.spacing(1.25),
    boxShadow: theme.shadows[24],
    marginTop: theme.spacing(1)
  }
}));

const StyledListItem = styled(MenuItem, {
  shouldForwardProp: propName => propName !== 'isIcon'
})<{ isIcon: boolean }>(({ isIcon, theme }) => ({
  marginBottom: 0,
  paddingLeft: isIcon ? theme.spacing(0) : theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

export type ActionMenuItemProps = {
  content?: ReactNode;
  id: string;
  icon?: ElementType;
  iconColor?: keyof Palette;
  index?: number;
  href?: string;
  meta?: Record<string, unknown>;
  onClose?: () => void;
  onSelect?: (meta?: Record<string, unknown>) => void | Promise<void>;
};

const ActionMenuItem: FC<ActionMenuItemProps> = props => {
  const label = typeof props.content === 'string' ? props.content : undefined;
  const testId = useCamelCase(label || props.id);

  const onClick = useCallback(() => {
    if (props.href) {
      window.location.replace(props.href);
    }

    if (props.onSelect) {
      props.onSelect(props.meta);
    }

    if (props.onClose) {
      props.onClose();
    }
  }, [props.href, props.meta, props.onClose, props.onSelect]);

  const Icon = props.icon;

  return (
    <StyledListItem
      aria-label={label}
      autoFocus={props.index === 0}
      data-testid={testId}
      disableRipple
      isIcon={!!Icon}
      onClick={onClick}>
      {Icon && (
        <Icon
          fontSize='small'
          sx={theme => ({
            color: theme.palette[props.iconColor ?? 'grey100']?.main,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
          })}
        />
      )}
      {props.content}
    </StyledListItem>
  );
};

export type ActionMenuProps = {
  actions: ActionMenuItemProps[];
  className?: string;
  title: string;
  trigger: ReactElement;
};

export const ActionMenu: FC<ActionMenuProps> = props => {
  const menuId = useId();
  const triggerId = useId();

  const title = useCamelCase(props.title);

  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null);

  const isOpen = Boolean(anchorEl);

  const onClick = useCallback(event => {
    setAnchorEl(event?.target);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const trigger = useMemo(() => {
    return props.trigger
      ? cloneElement(props.trigger, {
          ...props.trigger.props,
          'aria-controls': isOpen ? menuId : undefined,
          'aria-expanded': isOpen ? 'true' : undefined,
          'aria-haspopup': 'true',
          'data-component': 'actionMenuTrigger',
          'data-testid': title,
          disableElevation: true,
          id: triggerId,
          onClick,
          role: 'button'
        })
      : null;
  }, [props.trigger, isOpen, title, triggerId, menuId, onClick]);

  return (
    <>
      {trigger}
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': triggerId
        }}
        anchorEl={anchorEl}
        className={props.className}
        data-component='actionMenu'
        data-testid={title}
        elevation={0}
        id={menuId}
        onClose={onClose}
        open={isOpen}>
        {props.actions?.length ? (
          props.actions.map((action, index) => (
            <ActionMenuItem
              content={action.content}
              href={action.href}
              icon={action.icon}
              iconColor={action.iconColor}
              id={action.id}
              index={index}
              key={action.id}
              meta={action.meta}
              onClose={onClose}
              onSelect={action.onSelect}
            />
          ))
        ) : (
          <ActionMenuItem content='No Items' id='noItems' index={0} />
        )}
      </StyledMenu>
    </>
  );
};
