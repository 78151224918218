import {
  CircularProgress,
  ExtendButtonBase,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled
} from '@mui/material';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { useCamelCase } from '@vestwell-frontend/hooks';

import {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributeAnchorTarget,
  RefAttributes
} from 'react';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type ButtonProps = MuiButtonProps<
  ExtendButtonBase<ButtonTypeMap>,
  {
    'data-component'?: string;
    'data-testid'?: string;
    loading?: boolean;
    target?: HTMLAttributeAnchorTarget | undefined;
    to?: string;
  }
>;

const StyledButton = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'loading'
})<
  ButtonProps & {
    component?: ForwardRefExoticComponent<
      LinkProps & RefAttributes<HTMLAnchorElement>
    >;
  }
>(({ color, ...props }) => ({
  backgroundColor: props.loading
    ? props.theme.palette.grey700.main
    : props.disabled && props.variant === 'inline'
    ? 'transparent'
    : undefined,
  color: props.loading
    ? 'transparent !important'
    : props.variant === 'inline'
    ? props.theme.palette[color ?? 'primary'].main
    : undefined
}));

const LoadingIndicator = styled(CircularProgress, {
  shouldForwardProp: prop => prop !== 'loading'
})(props => ({
  color: props.theme.palette.action.disabled,
  position: 'absolute'
}));

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const dataTestId = useCamelCase(
      typeof props.children === 'string' ? props.children : ''
    );

    return (
      <StyledButton
        {...props}
        component={props.to ? Link : undefined}
        data-component={props['data-component']}
        data-disabled={props.disabled || props.loading}
        data-testid={props['data-testid'] || dataTestId}
        disabled={props.disabled || props.loading}
        ref={ref}
        to={props.to}>
        {props.loading && <LoadingIndicator color='inherit' size={16} />}
        {props.children}
      </StyledButton>
    );
  }
);

Button.defaultProps = {
  color: 'primary',
  'data-component': 'button',
  disabled: false,
  loading: false,
  variant: 'contained'
};

Button.displayName = 'Button';
