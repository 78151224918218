import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Unstable_Grid2 as Grid, styled } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  AuthIcon,
  Button,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback, useEffect } from 'react';

import { SignInLink } from '../components';
import { mfaNudge, MfaNudgeState } from '../config/mfaNudge';

const StyledModalBody = styled(ModalBody)({
  background: 'linear-gradient(180deg, #DFF4FF 0%, #FFFFFF 45.01%), #FFFFFF'
});

const StyledAuthIcon = styled(AuthIcon)(({ theme }) => ({
  height: theme.spacing(35),
  width: theme.spacing(30)
}));

export const MfaNudgeStartPage: FC = () => {
  const ctx = useWizard<MfaNudgeState>();

  useDocumentTitle(ctx.step.title as string);

  useEffect(() => {
    ctx.setState({ ...mfaNudge.initialState });
  }, []);

  const onContinue = useCallback(() => {
    ctx.setState({ isUserReadyToEnableMfa: true });
  }, []);

  return (
    <>
      <StyledModalBody>
        <Grid
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={4}>
          <Grid
            alignContent='center'
            display='flex'
            justifyContent='center'
            lg={12}>
            <StyledAuthIcon data-component='authIcon' />
          </Grid>
          <Grid
            alignItems='center'
            display='flex'
            justifyContent='center'
            lg={12}>
            <Text
              className='text-center'
              color='oxfordBlue'
              mb={4}
              variant='b2'>
              {ctx.step.title}
            </Text>
          </Grid>
          <Grid display='flex' justifyContent='center' lg={10} xs={12}>
            <Text className='text-center' mb={0}>
              We require you to enable an added layer of protection on your
              account to help further protect your sensitive information.
            </Text>
          </Grid>
          <Grid
            alignItems='center'
            display='flex'
            justifyContent='center'
            lg={12}>
            <Text className='text-center' mb={0}>
              Setup is easy and will only take a few minutes.
            </Text>
          </Grid>
        </Grid>
      </StyledModalBody>
      <ModalFooter justifyBetween noBoxShadow>
        <SignInLink className='mb-0' forceLogout />
        <Button
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}
          onClick={onContinue}>
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};

MfaNudgeStartPage.displayName = 'MfaNudgeStartPage';
