import { Alert } from '@mui/material';
import { usePostForgotPassword } from '@sentinel/hooks';
import { validateEmail } from '@vestwell-frontend/helpers';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Form, FormField, Text } from '@vestwell-frontend/ui';

import { useState } from 'react';
import * as yup from 'yup';

import { SignInLink, SubmitButton } from '../components';

export const forgotPasswordSchema = yup.object().shape({
  email: validateEmail('Please use standard email format').required('Required'),
  emailConfirm: validateEmail('Please use standard email format')
    .oneOf([yup.ref('email'), undefined], 'Emails Must Match')
    .required('Required')
});

export function ForgotPasswordPage() {
  useDocumentTitle('Password Reset');

  const [values, setValues] = useState({
    email: '',
    emailConfirm: ''
  });

  const forgotPassword = usePostForgotPassword();

  const onSubmit = async values => {
    const res = await forgotPassword.mutateAsync({
      data: {
        email: values.email
      }
    });

    console.log(res.link);
  };

  return (
    <>
      {forgotPassword.isSuccess ? (
        <div className='px-8'>
          <Text
            align='center'
            color='emphasize'
            data-testid='resetLinkEmailedTitle'
            mb={8}
            variant='b2'>
            Reset Link Emailed
          </Text>
          <Text color='grey100' variant='f2'>
            Thanks for submitting your request.
          </Text>
          <Text color='grey100'>
            If you have an account associated with: <b>{values?.email}</b> you
            can expect to see a password reset link in your inbox within{' '}
            <b>10 minutes</b>.
          </Text>
          <Text color='grey100' mb={8}>
            If you do not see an email in that time, please check your spam
            folder or you may need to try a different email address. It can be a
            personal or work email address, so try both if you are having
            trouble.
          </Text>
          <SignInLink />
        </div>
      ) : (
        <>
          <Text
            align='center'
            color='emphasize'
            data-testid='forgotPasswordTitle'
            mb={8}
            variant='b2'>
            Password Reset
          </Text>
          <Form
            className='w-64'
            initialValues={{
              email: '',
              emailConfirm: ''
            }}
            onChange={setValues}
            onSubmit={onSubmit}
            validationSchema={forgotPasswordSchema}>
            <FormField
              className='mb-2 w-64'
              fieldClassName='w-64'
              format='email'
              label='Email'
              name='email'
            />
            <FormField
              className='mb-2 w-64'
              fieldClassName='w-64'
              format='email'
              label='Confirm email'
              name='emailConfirm'
            />
            {forgotPassword.error && (
              <Alert className='mt-0 w-fit-content' severity='error'>
                {forgotPassword.error?.message}
              </Alert>
            )}
            <Text color='grey100' variant='i1'>
              Enter a valid email associated with your account, and we will send
              you a link to reset your password.
            </Text>
            <SubmitButton>Send Password Reset Link</SubmitButton>
          </Form>
          <SignInLink />
        </>
      )}
    </>
  );
}

ForgotPasswordPage.displayName = 'ForgotPasswordPage';
