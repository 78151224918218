import { Palette, PaletteColor, styled } from '@mui/material';

import * as React from 'react';
import { FC, ReactNode } from 'react';

import { PLACEHOLDER } from '../consts';
import { hexToRgba } from '../helpers';
import { Loading } from './Loading';

export type PillProps = {
  'data-testid'?: string;
  backgroundColor: keyof Palette;
  backgroundColorOpacity?: number;
  className?: string;
  fontColor?: keyof Palette;
  isLoading?: boolean;
  value: ReactNode;
};

const StyledBox = styled('div')<
  Pick<PillProps, 'backgroundColor' | 'backgroundColorOpacity'> & {
    color: PillProps['fontColor'];
  }
>(({ theme, color, backgroundColor, backgroundColorOpacity }) => ({
  backgroundColor: hexToRgba(
    (theme.palette[backgroundColor] as PaletteColor)?.main,
    backgroundColorOpacity
  ),
  borderRadius: theme.spacing(2),
  color: (theme.palette[color] as PaletteColor)?.main,
  fontSize: theme.spacing(2.5),
  fontWeight: 800,
  padding: theme.spacing(1, 2),
  textTransform: 'uppercase',
  width: 'fit-content'
}));

export const Pill: FC<PillProps> = props => (
  <StyledBox
    backgroundColor={props.backgroundColor}
    backgroundColorOpacity={props.backgroundColorOpacity}
    className={props.className}
    color={props.fontColor}
    data-component='pill'
    data-testid={props['data-testid']}>
    {props.value || <span>{props.isLoading ? <Loading /> : PLACEHOLDER}</span>}
  </StyledBox>
);

Pill.displayName = 'Pill';

Pill.defaultProps = {
  backgroundColorOpacity: 0.3
};
