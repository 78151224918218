import {
  Palette,
  PaletteColor,
  styled,
  Typography,
  TypographyProps
} from '@mui/material';

import { FC, ReactNode } from 'react';

import { Loading } from './Loading';

const htmlTag = {
  a1: 'span',
  a2: 'h1',
  b1: 'span',
  b2: 'h1',
  c1: 'span',
  c2: 'h2',
  d1: 'span',
  d2: 'h2',
  e1: 'span',
  e2: 'h2',
  f1: 'span',
  f2: 'h3',
  g1: 'p',
  g2: 'span',
  i1: 'p',
  i2: 'span',
  j1: 'span',
  j2: 'p',
  k3: 'span'
};

export type TextProps = {
  backgroundColor?: keyof Palette;
  className?: string;
  color?: keyof Palette;
  component?: ReactNode;
  id?: string;
  isLoading?: boolean;
  role?: string;
  tabIndex?: number;
} & TypographyProps;

const TextContainer = styled(Typography, {
  shouldForwardProp: propName => propName !== 'isLoading'
})<TextProps>(props => {
  return {
    backgroundColor:
      (props.theme.palette[props.backgroundColor] as PaletteColor)?.main ||
      props.theme.palette[props.bgcolor as string]?.main,
    color: props.theme.palette[props.color]?.main
  };
});

TextContainer.displayName = 'TextContainer';

export const Text: FC<TextProps> = props => {
  return (
    <TextContainer
      component={props.component || htmlTag[props.variant]}
      data-component='text'
      data-variant={props.variant}
      {...props}
      aria-label={props.isLoading ? 'Loading' : props['aria-label']}>
      {props.isLoading ? <Loading variant={props.variant} /> : props.children}
    </TextContainer>
  );
};

Text.displayName = 'Text';

Text.defaultProps = {
  className: '',
  maxWidth: '45rem',
  mb: 4,
  variant: 'g1'
};
