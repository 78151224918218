import {
  EmailOutlined,
  PermPhoneMsgOutlined,
  ScreenshotOutlined
} from '@mui/icons-material';
import { Unstable_Grid2 as Grid, Stack } from '@mui/material';
import {
  GetMfaMethodsResDto,
  useGetMfaMethods,
  useGetStatus,
  usePostMfaSend
} from '@sentinel/hooks';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormatEmail,
  FormatPhoneNumber,
  FormRadioGroup,
  RadioButton,
  Text
} from '@vestwell-frontend/ui';

import { differenceInSeconds } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import * as yup from 'yup';

import {
  AppVerification,
  EmailVerification,
  HelpModal,
  PhoneVerification,
  SignInLink,
  SubmitButton
} from '../components';
import { useBackButtonBehavior } from '../hooks';

const schema = yup.object().shape({
  mfaEntryId: yup.number().required('Required')
});

export function MfaPage() {
  useDocumentTitle('Two-Factor Authentication');

  useBackButtonBehavior();

  useGetStatus({
    query: {
      refetchOnWindowFocus: true,
      useErrorBoundary: true
    }
  });

  const mfaMethods = useGetMfaMethods({
    query: {
      select: data => ({
        all: data,
        formatted: data.reduce(
          (acc, method) => {
            if (method.entryType === 'phone') {
              return {
                ...acc,
                phone: (acc.phone ?? []).concat(method)
              };
            }

            return {
              ...acc,
              [method.entryType]: method
            };
          },
          {} as {
            app: GetMfaMethodsResDto;
            email: GetMfaMethodsResDto;
            phone: GetMfaMethodsResDto[];
          }
        )
      })
    }
  });

  const postMfaSend = usePostMfaSend();

  const [method, setMethod] = useState<
    Partial<GetMfaMethodsResDto> & { subType?: 'text' | 'voice' }
  >({ mfaEntryId: null });

  const [codeLastSentTime, setCodeLastSentTime] = useLocalStorage(
    'codeLastSentTime',
    {}
  );

  const onChangeAuthMethod = useCallback(() => {
    setMethod({ mfaEntryId: null });
  }, []);

  const onMethodSelect = useCallback(
    async (values: { mfaEntryId: number; subType?: 'text' | 'voice' }) => {
      const primaryMethod = (mfaMethods.data?.all ?? []).find(
        ({ isPrimary }) => isPrimary
      );

      if (!values.mfaEntryId) {
        if (primaryMethod) {
          setMethod(primaryMethod);
          return;
        } else {
          return;
        }
      }

      const selectedMethod = (mfaMethods.data?.all ?? []).find(
        ({ mfaEntryId }) => mfaEntryId === values.mfaEntryId
      );

      setMethod({
        ...selectedMethod,
        subType: values.subType
      });

      if (selectedMethod.entryType === 'phone' && !values.subType) {
        return;
      }

      const seconds = 30;

      const diff =
        seconds -
        differenceInSeconds(
          Date.now(),
          codeLastSentTime?.[selectedMethod.mfaEntryId]
        );

      if (
        !codeLastSentTime?.[selectedMethod.mfaEntryId] ||
        diff > seconds ||
        diff < 0
      ) {
        setCodeLastSentTime(prevState => ({
          ...prevState,
          [selectedMethod.mfaEntryId]: Date.now()
        }));

        const res = await postMfaSend.mutateAsync({
          data: {
            mfaEntryId: selectedMethod.mfaEntryId,
            subType: values.subType,
            type: selectedMethod.entryType
          }
        });

        console.log(res?.code);
      }
    },
    [codeLastSentTime]
  );

  useEffect(() => {
    if (mfaMethods.data?.all?.length) {
      onMethodSelect({
        mfaEntryId: null
      });
    }
  }, [mfaMethods.data]);

  return (
    <Grid container display='flex' flexDirection='column' minHeight={530} p={2}>
      {method.entryType === 'app' && <AppVerification />}
      {method.entryType === 'email' && <EmailVerification email={method} />}
      {method.entryType === 'phone' && (
        <PhoneVerification onSubTypeSelect={onMethodSelect} phone={method} />
      )}
      {method.mfaEntryId !== null && (
        <Button
          className='w-full'
          data-testid='tryAnotherMethod'
          onClick={onChangeAuthMethod}
          variant='inline'>
          Try another way to authenticate
        </Button>
      )}
      {method.mfaEntryId === null && (
        <Grid
          alignItems='center'
          container
          direction='column'
          display='flex'
          height='100%'
          justifyContent='space-between'>
          <Grid>
            <Text align='center' className='w-100 mb-8' variant='b2'>
              Two-Factor Authentication
            </Text>
          </Grid>

          <Form
            className='contents'
            initialValues={{
              mfaEntryId:
                mfaMethods.data?.formatted?.app?.mfaEntryId ||
                mfaMethods?.data?.formatted?.phone?.[0]?.mfaEntryId ||
                mfaMethods?.data?.formatted?.email?.mfaEntryId
            }}
            onSubmit={onMethodSelect}
            validateOnMount={false}
            validationSchema={schema}>
            <Grid flexGrow={1}>
              <FormRadioGroup
                hideLabel
                label='Mfa Method'
                name='mfaEntryId'
                variant='card'>
                <Text align='center' color='grey50' variant='g2'>
                  How would you like us to verify your identity?
                </Text>
                {mfaMethods.data?.formatted?.app && (
                  <RadioButton
                    className='ml-0'
                    label={
                      <>
                        <Stack
                          alignItems='center'
                          direction='row'
                          spacing={2.5}>
                          <ScreenshotOutlined
                            color='grey400'
                            fontSize='small'
                          />
                          <Text
                            color='grey50'
                            component='h2'
                            mb={0}
                            variant='f2'>
                            Via authentication app
                          </Text>
                        </Stack>
                        <Text color='grey200' mb={0} ml={7.5} variant='g1'>
                          ex. Google Authenticator
                        </Text>
                      </>
                    }
                    value={mfaMethods.data?.formatted?.app?.mfaEntryId}
                  />
                )}
                {mfaMethods.data?.formatted?.phone?.map(phone => (
                  <RadioButton
                    className='ml-0'
                    key={phone.value}
                    label={
                      <>
                        <Stack
                          alignItems='center'
                          direction='row'
                          spacing={2.5}>
                          <PermPhoneMsgOutlined
                            color='grey400'
                            fontSize='small'
                          />
                          <Text
                            color='grey50'
                            component='h2'
                            mb={0}
                            variant='f2'>
                            Get a code via{' '}
                            <FormatPhoneNumber mask value={phone.value} />
                          </Text>
                        </Stack>
                        <Text color='grey200' mb={0} ml={7.5} variant='g1'>
                          Standard rates may apply.
                        </Text>
                      </>
                    }
                    value={phone.mfaEntryId}
                  />
                ))}
                {mfaMethods.data?.formatted?.email && (
                  <RadioButton
                    className='ml-0'
                    label={
                      <>
                        <Stack
                          alignItems='center'
                          direction='row'
                          spacing={2.5}>
                          <EmailOutlined color='grey400' fontSize='small' />
                          <Text
                            color='grey50'
                            component='h2'
                            mb={0}
                            variant='f2'>
                            Via email
                          </Text>
                        </Stack>
                        <Text color='grey200' mb={0} ml={7.5} variant='g1'>
                          <FormatEmail
                            mask
                            value={mfaMethods.data.formatted.email.value}
                          />
                        </Text>
                      </>
                    }
                    value={mfaMethods.data.formatted.email.mfaEntryId}
                  />
                )}
              </FormRadioGroup>
            </Grid>
            <SubmitButton>Continue</SubmitButton>
          </Form>
          <SignInLink forceLogout />
          <HelpModal />
        </Grid>
      )}
    </Grid>
  );
}

MfaPage.displayName = 'MfaPage';
