import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import { useGetSessionInfo, usePostVerifyPassword } from '@sentinel/hooks';
import {
  Button,
  Form,
  FormField,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  PLACEHOLDER,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { State } from './types';

type VerifyPasswordProps = {
  isMfaSetup: boolean;
  onClose?: () => void | Promise<void>;
};

const schema = yup.object().shape({
  password: yup.string().required('Required')
});

export const VerifyPassword: FC<VerifyPasswordProps> = props => {
  const ctx = useWizard<State>();

  const user = useGetSessionInfo('user');

  const verify = usePostVerifyPassword({
    mutation: {
      onSuccess: data => {
        if (data.isValid) {
          ctx.setState({
            passwordVerified: true
          });
        }
      }
    }
  });

  const onSubmit = useCallback(async values => {
    await verify.mutateAsync({
      data: {
        password: values.password
      }
    });
  }, []);

  return (
    <>
      <Form
        className='contents'
        initialValues={{ password: '' }}
        onChange={verify.reset}
        onSubmit={onSubmit}
        validateOnMount={false}
        validationSchema={schema}>
        <ModalBody>
          <Grid container direction='column' spacing={4}>
            <Grid lg={10} md={12}>
              <Text mb={0}>
                For security, please enter your password for{' '}
                <strong>{user.data?.email ?? PLACEHOLDER}</strong>.
              </Text>
            </Grid>
            <Grid
              className='flex-grow'
              display='flex'
              flexDirection='column'
              lg={6}
              md={9}
              xs={12}>
              <FormField
                autoComplete='current-password'
                autoFocus
                className='w-100'
                fieldClassName='w-100'
                label='Password'
                name='password'
                revealable
                type='password'
              />
              {verify.data?.isValid === false && (
                <Grid display='flex' flexDirection='column'>
                  <Alert
                    classes={{ root: 'flex-shrink inline-flex' }}
                    severity='error'
                    variant='standard'>
                    The password you entered is incorrect. Please try again.
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.onClose} variant='text'>
            Cancel
          </Button>
          <FormSaveButton
            endIcon={
              props.isMfaSetup ? (
                <KeyboardArrowRightOutlined fontSize='small' />
              ) : undefined
            }
            variant='contained'>
            {props.isMfaSetup ? 'Next: Setup' : 'Continue'}
          </FormSaveButton>
        </ModalFooter>
      </Form>
    </>
  );
};

VerifyPassword.displayName = 'VerifyPassword';
