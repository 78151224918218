import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, Text } from '@vestwell-frontend/ui';

import { FC } from 'react';

import { useConfig } from '../contexts';

const Contact = styled('div')(props => ({
  borderRadius: props.theme.spacing(4),
  marginBottom: props.theme.spacing(2),
  padding: props.theme.spacing(1, 4),
  width: 'fit-content'
}));

export const Help: FC = () => {
  const config = useConfig();

  if (!config.login?.contacts?.length && !config.login?.resources?.length)
    return null;

  return (
    <div className='flex flex-col text-center items-center my-8'>
      {!!config.login?.resources?.length && (
        <Contact className='flex justify-center bg-white'>
          {config.login.resources.map((resource, index) => (
            <div className='flex' key={resource.description}>
              <Link external to={resource.url}>
                {resource.description}
              </Link>
              {index !== config.login.resources.length - 1 && (
                <Divider className='mx-2' flexItem orientation='vertical' />
              )}
            </div>
          ))}
        </Contact>
      )}
      {!!config.login?.contacts?.length && (
        <>
          <Text mb={2} mt={4}>
            Need Help?
          </Text>
          {config.login.contacts.map(contact => (
            <Contact className='bg-white' key={contact.description}>
              {contact.description}{' '}
              <Link to={`mailto:${contact.email}`} type='email'>
                {contact.email}
              </Link>{' '}
              or call{' '}
              <Link to={`tel:${contact.phone}`} type='phone'>
                {contact.phone}
              </Link>
            </Contact>
          ))}
        </>
      )}
    </div>
  );
};

Help.displayName = 'Help';
