import { Alert, Unstable_Grid2 as Grid, styled } from '@mui/material';
import { usePostMfaVerify } from '@sentinel/hooks';
import { Form, FormField, Text } from '@vestwell-frontend/ui';

import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

import { routes } from '../router/routes';
import { SubmitButton } from './SubmitButton';

const StyledFormField = styled(FormField)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: theme.spacing(50)
}));

const schema = yup.object().shape({
  appVerification: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

export const AppVerification: FC = memo(() => {
  const navigate = useNavigate();

  const postMfaVerify = usePostMfaVerify();

  const onSubmit = useCallback(async values => {
    await postMfaVerify.mutateAsync({
      data: { code: values.appVerification, type: 'app' }
    });
  }, []);

  useUpdateEffect(() => {
    if (postMfaVerify.data?.session?.isMfaVerified) {
      navigate(routes.PERSONAS);
    }
  }, [postMfaVerify.data?.session?.isMfaVerified]);

  return (
    <>
      <Grid>
        <Text
          align='center'
          className='w-100'
          data-testid='appVerificationTitle'
          variant='b2'>
          Verify by Authentication App
        </Text>
      </Grid>
      <Form
        className='contents'
        onChange={postMfaVerify.reset}
        onSubmit={onSubmit}
        validationSchema={schema}>
        <Grid
          alignItems='center'
          container
          display='flex'
          flexDirection='column'
          flexGrow={1}
          justifyContent='space-between'>
          <Grid xs={12}>
            <Text align='center' color='grey100' variant='i1'>
              Enter the 6-digit code generated by your authentication app.
            </Text>
          </Grid>

          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            flexGrow={1}
            justifyContent='center'
            xs={12}>
            <StyledFormField
              align='center'
              autoComplete='one-time-code'
              autoFocus
              disabled={postMfaVerify.error?.message?.startsWith(
                'Your account has been temporarily locked'
              )}
              format='numericString'
              label='Security Code'
              maxLength={6}
              name='appVerification'
              variant='large'
            />
          </Grid>
          {postMfaVerify.error && (
            <Alert className='w-fit-content' severity='error'>
              {postMfaVerify.error.message}
            </Alert>
          )}
          <SubmitButton
            disabled={
              !!postMfaVerify.error?.message?.startsWith(
                'Your account has been temporarily locked'
              )
            }>
            Continue
          </SubmitButton>
        </Grid>
      </Form>
    </>
  );
});

AppVerification.displayName = 'AppVerification';
