import { Unstable_Grid2 as Grid } from '@mui/material';
import { useCamelCase, useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormRadioGroup,
  FormSaveButton,
  RadioButton,
  Text
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { useConfig } from '../contexts';
import { routes } from '../router/routes';

const schema = yup.object().shape({
  type: yup.string().required('Required')
});

export const AccountSetupPage: FC = () => {
  const config = useConfig();

  useDocumentTitle(config.login?.accountSetup?.page?.title);

  const onSubmit = useCallback(values => {
    window.location.href = values?.type;
  }, []);

  return (
    <>
      <Text align='center' color='emphasize' mb={8} variant='c2'>
        {config.login?.accountSetup?.page?.title}
      </Text>
      <Form
        className='contents'
        data-testid={useCamelCase(config.login?.accountSetup?.page?.title)}
        initialValues={{ type: '' }}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={schema}>
        <Grid
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={4}>
          <Grid disableEqualOverflow lg={10} xs={12}>
            <FormRadioGroup
              fullWidth
              hideError
              hideLabel
              label={config.login?.accountSetup?.page?.title}
              name='type'
              position='left'
              variant='card'>
              {config.login?.accountSetup?.page?.selections?.map(option => {
                return (
                  <RadioButton
                    className='py-4'
                    key={option?.title}
                    label={
                      <>
                        <Text
                          color='grey50'
                          component='h2'
                          mb={2}
                          mt={1}
                          variant='g2'>
                          {option?.title}
                        </Text>
                        <Text color='grey300' mb={0} variant='i1'>
                          {option?.description}
                        </Text>
                      </>
                    }
                    value={option?.link}
                  />
                );
              })}
            </FormRadioGroup>
          </Grid>
          <Grid lg={10} xs={12}>
            <FormSaveButton className='w-100' data-testid='continue'>
              Continue
            </FormSaveButton>
          </Grid>
          <Grid lg={10} xs={12}>
            <Button
              className='w-100'
              data-testid='cancel'
              to={routes.SIGN_IN}
              variant='text'>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

AccountSetupPage.displayName = 'AccountSetupPage';
