import { createSvgIcon, SvgIconProps } from '@mui/material';

import { FC } from 'react';

export const AuthIcon: FC<SvgIconProps> = createSvgIcon(
  <svg
    fill='none'
    height='122'
    viewBox='0 0 120 122'
    width='120'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.2942 1C11.8281 1 8 4.40275 8 8.65618V60.3354C8 73.9464 16.5069 88.1954 33.0953 102.657C44.7922 112.865 55.648 119.458 56.0734 119.883C58.8381 121.372 62.2408 121.372 65.0056 119.883C65.4309 119.671 77.5436 112.865 88.8152 102.657C104.766 87.9827 112.847 73.9464 112.847 60.5481V8.65618C112.847 4.40275 109.232 1 104.553 1H16.2942Z'
      stroke='#DDC04A'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <g filter='url(#filter0_d_732_70784)'>
      <path
        clipRule='evenodd'
        d='M36.369 98.9006L36.371 98.9023C42.0515 103.86 47.5434 107.952 51.6789 110.864C53.7435 112.317 55.4657 113.473 56.7053 114.297C56.9655 114.47 57.215 114.635 57.4439 114.787C57.7428 114.985 58.0064 115.159 58.2121 115.297C58.3813 115.41 58.5485 115.523 58.6935 115.624C59.9372 116.187 61.4368 116.144 62.6418 115.496L62.7082 115.46L62.7757 115.426C62.7707 115.429 62.7877 115.419 62.8257 115.397C63.2312 115.167 66.0319 113.579 70.0709 110.876C74.4267 107.962 80.0574 103.862 85.4544 98.9762C101.002 84.6681 107.862 71.874 107.862 60.548V8.65616C107.862 7.49643 106.833 5.98352 104.552 5.98352H16.2931C14.1388 5.98352 12.9824 7.57579 12.9824 8.65616V60.3354C12.9824 71.8068 20.1952 84.8004 36.369 98.9006Z'
        fill='white'
        fillRule='evenodd'
      />
    </g>
    <rect
      height='35.2408'
      rx='4.27161'
      stroke='#39CC97'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      width='46.2758'
      x='37.292'
      y='39.4443'
    />
    <rect
      fill='#E7FFF3'
      height='29.5453'
      rx='1.77984'
      width='40.5803'
      x='40.1387'
      y='42.2921'
    />
    <path
      d='M46.5479 39.4444V30.9012C46.5479 23.2339 52.7634 17.0184 60.4306 17.0184V17.0184C68.0978 17.0184 74.3133 23.2339 74.3133 30.9012V39.4444'
      stroke='#39CC97'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <filter
      colorInterpolationFilters='sRGB'
      filterUnits='userSpaceOnUse'
      height='112.881'
      id='filter0_d_732_70784'
      width='97.7276'
      x='11.5586'
      y='5.98352'>
      <feFlood floodOpacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.42387' />
      <feGaussianBlur stdDeviation='0.711935' />
      <feComposite in2='hardAlpha' operator='out' />
      <feColorMatrix
        type='matrix'
        values='0 0 0 0 0.172549 0 0 0 0 0.560784 0 0 0 0 0.772549 0 0 0 0.09 0'
      />
      <feBlend
        in2='BackgroundImageFix'
        mode='normal'
        result='effect1_dropShadow_732_70784'
      />
      <feBlend
        in='SourceGraphic'
        in2='effect1_dropShadow_732_70784'
        mode='normal'
        result='shape'
      />
    </filter>
    <path
      d='M51.5312 39.4444V30.9011C51.5312 25.9863 55.5156 22.002 60.4304 22.002V22.002C65.3453 22.002 69.3296 25.9863 69.3296 30.9011V39.4444'
      stroke='#39CC97'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <path
      d='M48.3262 56.1749L56.6914 64.5401L72.176 49.0555'
      stroke='#39CC97'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <defs></defs>
  </svg>,
  'AuthIcon'
);

AuthIcon.defaultProps = {
  viewBox: '0 0 120 122'
};

AuthIcon.displayName = 'AuthIcon';
